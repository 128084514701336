import './StreamConfiguration.scss'

import {
  AccordionItem,
  Button,
  Form,
  FormGroup,
  Select,
  SelectItem,
  TextInput
} from 'carbon-components-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import FormLabelWithTooltip from '../FormLabelWithToolTip'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ECameraConfigurationType } from '../../types/camera_configuration'
import { NavLink } from 'react-router-dom'
import {
  DefaultQuidoIoTriggerConfiguration,
  DefaultBMCIoTriggerConfiguration,
  EIoTriggerConfigurationType,
  EMqttConfigurationType,
  EMqttQos,
  EQuidoDeviceType,
  IIoTriggerConfiguration
} from '../../types/eventdataOutputConfiguration'
import { ICoordinates, IStream, IStreamDetails } from '../../types/stream'
import { ECameraFrameMode, ICameraFrame } from '../../types/cameraFrame'
import DrawCanvas from '../DrawCanvas'
import { IBox, ProductModels } from '../../types/box'
import {
  EEventTriggerType,
  ICoordinateBasedEventTrigger
} from '../../types/eventTrigger'
import {
  Alert,
  Button as AntdButton,
  Checkbox,
  Col,
  Row,
  Space,
  Switch,
  Tooltip,
  Form as AntForm,
  CheckboxOptionType
} from 'antd'
import OperationalStatusCell from '../BoxList/cells/OperationalStatusCell'
import { ERuntimeState } from '../../types/runtimeState'
import {
  CloseOutlined,
  PushpinOutlined,
  ApiOutlined,
  UploadOutlined,
  InfoCircleOutlined,
  ControlOutlined,
  VideoCameraOutlined
} from '@ant-design/icons'
import { EErrorReason } from '../../types/errorReason'
import LocationPickerModal from '../LocationPicker/LocationPickerModal'
import { getIdComponent } from '../HelperComponents'
import { ILicenseStatus } from '../../types/licenseManagement'
import { userIsAtLeast, UserRole } from '../../types/user_role'
import ConfirmationDialog from '../ConfirmDialog/ConfirmationDialog'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStore } from '../../redux/store/configureStore'
import { loadStreamEventTriggers } from '../../redux/actions/eventTriggers'
import { useBoxFeatureFlagsState } from '../../helpers/hooks/useBoxFeatureFlags'
import { EBoxFeatureFlags } from '../../types/boxFeatureFlags'

const JOINT_COORDINATES_FORMAT_REGEX = /(-?\d\d*[\\.,]\d*)[\\.,]\s*(-?\d\d*[\\.,]\d*)/

interface IStreamConfigurationFormProps {
  box: IBox
  stream: IStream
  streamDetails?: IStreamDetails
  streamIndex: number
  maxStreamsReached: boolean
  licensesStatus: ILicenseStatus

  handleMqttConfigTypeChanged(
    mqttConfigurationType: [EMqttConfigurationType],
    streamID: string
  )

  handleMqttCompressionChanged(toggled: boolean, streamID: string)

  handleMqttQosChanged(qos: number, streamID: string)

  handleIoTriggerConfigChanged(
    IoTriggerConfig: IIoTriggerConfiguration,
    streamID: string
  ): void

  handleCameraConfigTypeChanged(
    cameraConfigurationType: ECameraConfigurationType,
    streamID: string
  )

  onStreamFormSubmit(
    boxId: string,
    streamId: string,
    streamDetails: IStreamDetails
  ): void

  onStreamDelete(boxId: string, streamId: string): void

  isSubmitting: boolean
  open: boolean
  isNew: boolean

  loadCameraFrame: Function
  resetCameraFrame: Function
  cameraFrame?: ICameraFrame
  isLoadingCameraFrame: boolean
  hideBlurNote: boolean
  setHideBlurNote: Function
}

const StreamConfigurationForm: React.FC<IStreamConfigurationFormProps> = (
  props
) => {
  const { t } = useTranslation()
  const { register, handleSubmit, errors } = useForm()
  const mqttConfig = props.streamDetails?.mqttConfig
  const cameraConfig = props.streamDetails?.cameraConfig
  const ioTriggerConfig = props.streamDetails?.ioTriggerConfig
  const stream = props.stream
  const streamIndex = props.streamIndex
  const isLoadingCameraFrame = props.isLoadingCameraFrame
  const loadCameraFrame = props.loadCameraFrame
  const cameraFrame = props.cameraFrame
  const resetCameraFrame = props.resetCameraFrame

  const [streamEnabled, setStreamEnabled] = useState(stream.enabled)
  const [debugEnabled, setDebugEnabled] = useState(stream.debug)
  const [recordTrackCalibration, setRecordTrackCalibration] = useState(
    stream.recordTrackCalibration ? stream.recordTrackCalibration : false
  )
  const [showLocationPicker, setShowLocationPicker] = useState<boolean>(false)
  const [coordinates, setCoordinates] = useState<
    ICoordinates | { latitude: number | string; longitude: number | string }
  >(stream.coordinates ? stream.coordinates : { latitude: '', longitude: '' })
  const [resetView, setResetView] = useState<boolean>(true)
  const [userSelectedCoordinates, setUserSelectedCoordinates] = useState<
    ICoordinates | undefined
  >(undefined)

  const dispatch = useDispatch()
  const { eventTriggers, fetchedStreamId } = useSelector(
    (state: ReduxStore) => {
      return {
        eventTriggers: state.eventTriggers.byIds,
        fetchedStreamId: state.eventTriggers.fetchedStreamId
      }
    }
  )

  const [
    coordinateBasedEventTriggers,
    setCoordinateBasedEventTriggers
  ] = useState<ICoordinateBasedEventTrigger[]>([])

  const callStreamEventTriggers = useCallback(() => {
    dispatch(loadStreamEventTriggers(props.box.id, stream.id))
  }, [dispatch, props.box.id, stream.id])

  useEffect(() => {
    if (fetchedStreamId === stream.id) {
      // @ts-ignore
      let newEventTriggers: ICoordinateBasedEventTrigger[] = Object.values(
        eventTriggers
      ).filter((trigger) =>
        [
          EEventTriggerType.virtualDoor,
          EEventTriggerType.originDestinationZone,
          EEventTriggerType.crossingLine,
          EEventTriggerType.regionOfInterest
          // @ts-ignore
        ].includes(trigger.objectType)
      )
      setCoordinateBasedEventTriggers(newEventTriggers)
    }
  }, [eventTriggers, fetchedStreamId, props.box.id, stream.id])

  const [hiddenEventTriggers, setHiddenEventTriggers] = useState<
    ICoordinateBasedEventTrigger[]
  >([])

  const [invalidCoordinates, setInvalidCoordinates] = useState(false)
  const [calibration, setCalibration] = useState(ECameraFrameMode.default)
  const [debugConfirmDialogVisible, setDebugConfirmDialogVisible] = useState(
    false
  )
  const [
    trackCalibrationConfirmDialogVisible,
    setTrackCalibrationConfirmDialogVisible
  ] = useState(false)

  const { boxFeatureFlags } = useBoxFeatureFlagsState(props.box.id)

  const isBmcAvailable = useMemo(() => {
    return boxFeatureFlags?.featureFlags.some(
      (flag) => flag.feature === EBoxFeatureFlags.bmc && flag.enabled
    )
  }, [boxFeatureFlags])

  const isDatacenterAvailable = useMemo(() => {
    return boxFeatureFlags?.featureFlags.some(
      (flag) => flag.feature === EBoxFeatureFlags.datacenter && flag.enabled
    )
  }, [boxFeatureFlags])

  const onSubmit = (data, event) => {
    event.preventDefault()
    if (!checkCoordinates(coordinates.latitude, coordinates.longitude)) {
      return
    }
    data.streamMeta.enabled = streamEnabled
    data.streamMeta.debug = debugEnabled
    data.streamMeta.recordTrackCalibration = recordTrackCalibration
    data.streamMeta.coordinates = coordinates
    props.onStreamFormSubmit(props.box.id, stream.id, data)
  }

  const toggleHideAllEventTriggers = () => {
    if (hiddenEventTriggers.length === 0) {
      setHiddenEventTriggers(coordinateBasedEventTriggers)
    } else {
      setHiddenEventTriggers([])
    }
  }

  const checkCoordinates = (lat, long) => {
    let latNumber = Number(lat)
    let longNumber = Number(long)

    let result =
      lat.length === 0 ||
      long.length === 0 ||
      isNaN(latNumber) ||
      latNumber < -90 ||
      latNumber > 90 ||
      isNaN(longNumber) ||
      longNumber < -180 ||
      longNumber > 180
    setInvalidCoordinates(result)
    return !result
  }

  const onDelete = (event) => {
    event.preventDefault()
    props.onStreamDelete(props.box.id, stream.id)
  }

  const handleEventConnectionConfigurationTypeChange = (data) => {
    props.handleMqttConfigTypeChanged(
      data.filter((datapoint) =>
        Object.values(EMqttConfigurationType).includes(datapoint)
      ),
      stream.id
    )
  }

  const handleIoTriggerConfigurationTypeChange = (data) => {
    if (data.includes('IoDevice')) {
      if (isBmcAvailable) {
        handleIoTriggerConfigChanged(DefaultBMCIoTriggerConfiguration)
      } else {
        handleIoTriggerConfigChanged(DefaultQuidoIoTriggerConfiguration)
      }
    } else {
      handleIoTriggerConfigChanged(undefined)
    }
  }

  const handleIoTriggerConfigChanged = (data) => {
    props.handleIoTriggerConfigChanged(data, stream.id)
  }

  const handleMqttCompressionChanged = (event) => {
    props.handleMqttCompressionChanged(event, stream.id)
  }

  const handleMqttQosChanged = (qos: number) => {
    props.handleMqttQosChanged(qos, stream.id)
  }

  const handleCameraConfigurationTypeChange = (event) => {
    props.handleCameraConfigTypeChanged(event.target.value, stream.id)
  }

  const titleNode = (stream: IStream, streamIndex: number) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>
          {/* eslint-disable-next-line eqeqeq */}
          {stream.name != undefined && stream.name.length > 0
            ? stream.name
            : t('configuration.group.stream.streamname.placeholder') +
              (streamIndex + 1)}
        </span>
        <OperationalStatusCell
          runtimeState={
            stream.streamStatus && stream.streamStatus.state
              ? stream.streamStatus.state
              : ERuntimeState.unknown
          }
          key={stream.streamStatus?.state}
          errorReason={
            stream.streamStatus &&
            (stream.streamStatus.state === 'NOT_OPERATIONAL' ||
              stream.streamStatus.state === 'WARNING')
              ? stream.streamStatus.errorReason
              : EErrorReason.unknown
          }
        />
      </div>
    )
  }

  const customMqttConfig =
    mqttConfig &&
    mqttConfig.find(
      (config) => config.configurationType === EMqttConfigurationType.custom
    )
  const defaultMqttConfig =
    mqttConfig &&
    mqttConfig.find(
      (config) => config.configurationType === EMqttConfigurationType.default
    )
  const datacenterMqttConfig =
    mqttConfig &&
    mqttConfig.find(
      (config) => config.configurationType === EMqttConfigurationType.datacenter
    )
  const eventConnectionDefault: (EMqttConfigurationType | string)[] = []
  customMqttConfig && eventConnectionDefault.push(EMqttConfigurationType.custom)
  defaultMqttConfig &&
    eventConnectionDefault.push(EMqttConfigurationType.default)
  datacenterMqttConfig &&
    eventConnectionDefault.push(EMqttConfigurationType.datacenter)
  ioTriggerConfig && eventConnectionDefault.push('IoDevice')

  const enableStreamTooltip = () => {
    if (props.maxStreamsReached && !stream.enabled) {
      return { title: t('configuration.maxStreamWarning') }
    } else if (
      props.licensesStatus &&
      !props.licensesStatus.streamsAvailable &&
      !stream.enabled
    ) {
      if (userIsAtLeast(UserRole.Admin)) {
        return {
          title: (
            <span
              dangerouslySetInnerHTML={{
                __html: t('configuration.noStreamsAvailableAdmin')
              }}
            ></span>
          )
        }
      } else {
        return { title: t('configuration.noStreamsAvailable') }
      }
    } else {
      return { title: '', visible: false }
    }
  }

  const onChangeCoordinate = (e, type) => {
    let newValue = e.target.value

    if (JOINT_COORDINATES_FORMAT_REGEX.test(newValue)) {
      let split = newValue.split(',')
      let latitude = split[0].trim().replace(',', '.')
      let longitude = split[1].trim().replace(',', '.')
      // only validate if previous input was incorrect on save
      if (invalidCoordinates) {
        checkCoordinates(latitude, longitude)
      }
      setCoordinates({ latitude: latitude, longitude: longitude })
    } else {
      newValue = newValue.trim().replace(',', '.')
      if (type === 'latitude') {
        if (invalidCoordinates) {
          checkCoordinates(newValue, coordinates.longitude)
        }
        setCoordinates({ latitude: newValue, longitude: coordinates.longitude })
      } else {
        if (invalidCoordinates) {
          checkCoordinates(coordinates.latitude, newValue)
        }
        setCoordinates({ latitude: coordinates.latitude, longitude: newValue })
      }
    }
    setResetView(true)
  }

  const getOptions = () => {
    let options: CheckboxOptionType[] = Object.values(
      EMqttConfigurationType
    ).map((item) => {
      let label = t(
        `configuration.group.mqttConnection.configurationType.values.${item}`
      )
      let modeDisabled = item === 'datacenter' && !isDatacenterAvailable
      if (modeDisabled) {
        label = (
          <Tooltip
            title={t(
              `configuration.group.mqttConnection.configurationType.notAvailable`
            )}
          >
            {label}
          </Tooltip>
        )
      }
      return {
        label: label,
        value: item,
        disabled: modeDisabled
      }
    })
    return options
  }
  const getIOOptions = () => {
    let options: { label: string; value: string }[] = []
    options.push({
      label: t(
        `configuration.group.mqttConnection.configurationType.values.ioDevice`
      ),
      value: 'IoDevice'
    })
    return options
  }

  useEffect(() => {
    if (props.open && !props.isNew) {
      loadCameraFrame(props.box.id, stream.id, false)
      callStreamEventTriggers()
    }
  }, [
    loadCameraFrame,
    callStreamEventTriggers,
    props.box.id,
    stream.id,
    props.open,
    props.isNew
  ])

  return (
    <>
      <AccordionItem
        onHeadingClick={(titleClickEvent) => {
          if (titleClickEvent.isOpen === true && stream.enabled) {
            loadCameraFrame(props.box.id, stream.id, calibration)
            callStreamEventTriggers()
          } else if (!titleClickEvent.isOpen) {
            resetCameraFrame(stream.id)
          }
        }}
        className="scc--stream--accordion-item"
        title={titleNode(stream, streamIndex)}
        open={props.open}
      >
        <div className="scc--boxdetail--stream-header">
          {getIdComponent(stream.id, 'configuration.group.stream.streamId', t)}
          {!props.hideBlurNote && stream.enabled && (
            <div className="scc--boxdetail--blur-hint">
              <Tooltip
                title={t('configuration.group.stream.blurHint.tooltip')}
                arrowPointAtCenter={true}
                trigger={'click'}
                placement={'bottomRight'}
              >
                <span>{t('configuration.group.stream.blurHint.title')}</span>
              </Tooltip>
              <Tooltip
                title={t('configuration.group.stream.blurHint.close')}
                placement={'left'}
              >
                <AntdButton
                  className="scc--boxdetail--close-blur-hint-button"
                  icon={<CloseOutlined />}
                  onClick={() => {
                    props.setHideBlurNote()
                  }}
                ></AntdButton>
              </Tooltip>
            </div>
          )}
        </div>

        <div className="scc--boxdetail--canvas">
          <DrawCanvas
            key={'somethingy'}
            boxId={props.box.id}
            streamId={props.stream.id}
            frame={cameraFrame}
            eventTriggers={coordinateBasedEventTriggers}
            highlightedEventTrigger={''}
            selectedEventTrigger={''}
            onSelectEventTrigger={function () {}}
            isEditable={false}
            isLoading={isLoadingCameraFrame}
            onRefreshButtonClick={(event) => {
              loadCameraFrame(
                props.box.id,
                stream.id,
                event.calibration,
                event.timestamp
              )
            }}
            hiddenEventTriggers={hiddenEventTriggers}
            toggleHideAllEventTriggers={toggleHideAllEventTriggers}
            calibration={calibration}
            recordTrackCalibration={props.stream.recordTrackCalibration}
            setCalibration={setCalibration}
            disabled={!stream.enabled}
          />
          {cameraFrame && stream.enabled && (
            <NavLink
              to={`/boxes/${props.box.id}/${stream.id}/setup`}
              onClick={() => resetCameraFrame(stream.id)}
            >
              <button className="bx--btn bx--btn--primary">
                {t('camera.frame.buttonDraw')}
              </button>
            </NavLink>
          )}
        </div>

        <Form
          onSubmit={handleSubmit(onSubmit)}
          name="stream-config-form"
          className="bx-grid"
        >
          <div className="bx--row">
            <div className="bx--col-lg-8">
              <AntForm className="scc--ioconnection">
                <Row className="scc--configheader">
                  <Col>
                    <InfoCircleOutlined className="scc--icon--api" />
                  </Col>
                  <Col>
                    <div className="scc--headerlabel">
                      {t('configuration.group.streamMeta.legendText')}
                    </div>
                  </Col>
                </Row>
                <FormLabelWithTooltip id="configuration.group.streamMeta.enabled" />
                <div>
                  <Tooltip placement="bottomLeft" {...enableStreamTooltip()}>
                    <Switch
                      checkedChildren={t('draw.toggle.on')}
                      unCheckedChildren={t('draw.toggle.off')}
                      checked={streamEnabled}
                      onChange={setStreamEnabled}
                      disabled={
                        !streamEnabled &&
                        !stream.enabled &&
                        (props.maxStreamsReached ||
                          !props.licensesStatus ||
                          !props.licensesStatus.streamsAvailable)
                      }
                    />
                  </Tooltip>
                </div>
                <FormLabelWithTooltip id="configuration.group.streamMeta.streamName" />
                <TextInput
                  id={'configuration.group.streamMeta.streamName.' + stream.id}
                  className="scc--formfield__value-set"
                  disabled={false}
                  invalidText={t('configuration.invalidText')}
                  labelText={false}
                  placeholder={t(
                    'configuration.group.streamMeta.streamName.placeholder'
                  )}
                  name="streamMeta.name"
                  defaultValue={stream.name}
                  ref={register}
                />
                <FormLabelWithTooltip id="configuration.group.streamMeta.coordinates" />
                <Col className="bx--row ">
                  <TextInput
                    className="scc--formfield__value-set"
                    id={'configuration.location.lat' + stream.id}
                    disabled={false}
                    labelText={false}
                    placeholder={t(
                      'configuration.group.streamMeta.coordinates.lat'
                    )}
                    invalidText={t(
                      'configuration.group.streamMeta.coordinates.latInvalid'
                    )}
                    helperText={t(
                      'configuration.group.streamMeta.coordinates.latInvalid'
                    )}
                    value={coordinates.latitude}
                    name="streamMeta.coordinates.latitude"
                    invalid={invalidCoordinates}
                    onChange={(e) => {
                      e.preventDefault()
                      onChangeCoordinate(e, 'latitude')
                    }}
                  />
                  <Row className="scc--carbonfield-row scc--location-picker-row">
                    <TextInput
                      className="scc--formfield__value-set scc--location-picker-long"
                      id={'configuration.location.lng' + stream.id}
                      disabled={false}
                      labelText={false}
                      placeholder={t(
                        'configuration.group.streamMeta.coordinates.lng'
                      )}
                      invalidText={t(
                        'configuration.group.streamMeta.coordinates.lngInvalid'
                      )}
                      helperText={t(
                        'configuration.group.streamMeta.coordinates.lngInvalid'
                      )}
                      value={coordinates.longitude}
                      name="streamMeta.coordinates.longitude"
                      invalid={invalidCoordinates}
                      onChange={(e) => {
                        e.preventDefault()
                        onChangeCoordinate(e, 'longitude')
                      }}
                    />
                    <AntdButton
                      className="scc--location-picker-button"
                      type="primary"
                      icon={<PushpinOutlined />}
                      onClick={() => {
                        setResetView(true)
                        if (
                          checkCoordinates(
                            coordinates.latitude,
                            coordinates.longitude
                          )
                        ) {
                          setUserSelectedCoordinates({
                            latitude: Number(coordinates.latitude),
                            longitude: Number(coordinates.longitude)
                          })
                        } else {
                          setUserSelectedCoordinates(undefined)
                        }
                        setShowLocationPicker(true)
                      }}
                    />
                  </Row>
                </Col>
              </AntForm>
              {cameraConfig && (
                <AntForm className="scc--ioconnection">
                  <Row className="scc--configheader">
                    <Col>
                      <VideoCameraOutlined className="scc--icon--api" />
                    </Col>
                    <Col>
                      <div className="scc--headerlabel">
                        {t('configuration.group.cameraConnection.legendText')}
                      </div>
                    </Col>
                  </Row>
                  <FormLabelWithTooltip id="configuration.group.cameraConnection.cameraConfigurationType" />
                  <Select
                    id={
                      'configuration.group.cameraConnection.cameraConfigurationType.' +
                      stream.id
                    }
                    className="scc--formfield__value-set"
                    inline={false}
                    labelText={false}
                    invalid={
                      errors['cameraConfig.configurationType'] !== undefined
                    }
                    invalidText={t('configuration.invalidText')}
                    name="cameraConfig.configurationType"
                    ref={register({ required: true })}
                    onChange={handleCameraConfigurationTypeChange}
                    defaultValue={cameraConfig.configurationType}
                  >
                    {Object.values(ECameraConfigurationType)
                      .filter(
                        (type) =>
                          !(
                            ECameraConfigurationType.bmaCameraConfiguration ===
                            type
                          ) ||
                          props.box?.productInfo?.model === ProductModels.BMA
                      )
                      .map((item) => (
                        <SelectItem
                          key={item}
                          value={item}
                          text={t(
                            `configuration.group.cameraConnection.cameraConfigurationType.values.${item}`
                          )}
                        />
                      ))}
                  </Select>
                  {cameraConfig.configurationType ===
                    ECameraConfigurationType.rawCameraConfiguration && (
                    <>
                      <FormLabelWithTooltip id="configuration.group.cameraConnection.connectionString" />
                      <TextInput
                        id="configuration.group.cameraConnection.cameraConnection.connectionString"
                        className="scc--formfield__value-set"
                        disabled={false}
                        invalid={
                          errors['cameraConfig.rawConnectionURI'] !== undefined
                        }
                        invalidText={t('configuration.invalidText')}
                        labelText={false}
                        placeholder={t(
                          'configuration.group.cameraConnection.connectionString.placeholder'
                        )}
                        name="cameraConfig.rawConnectionURI"
                        defaultValue={cameraConfig.rawConnectionURI}
                        ref={register({ required: true })}
                      />
                    </>
                  )}
                  {cameraConfig?.configurationType ===
                    ECameraConfigurationType.granularCameraConfiguration && (
                    <>
                      <FormLabelWithTooltip id="configuration.group.cameraConnection.cameraHost" />
                      <TextInput
                        id={
                          'configuration.group.cameraConnection.cameraHost.' +
                          stream.id
                        }
                        disabled={false}
                        invalid={
                          errors['cameraConfig'] &&
                          errors['cameraConfig']['host']
                        }
                        invalidText={t(
                          'configuration.group.cameraConnection.cameraHost.invalidText'
                        )}
                        labelText={false}
                        placeholder={t(
                          'configuration.group.cameraConnection.cameraHost.placeholder'
                        )}
                        name="cameraConfig.host"
                        defaultValue={cameraConfig ? cameraConfig.host : null}
                        ref={register({
                          required: true,
                          /*
                          See https://regex101.com/r/mU6bE3/9
                          This regex validates that the camera host is either
                          - an IP address of the form X.X.X.X where X is numeric and 1-3 digits
                          - or a valid hostname (host, host.tld, subdomain.host.tld, etc). In this case alphanumeric characters, . and - are allowed.
                            we restrict the last group from starting with numbers to avoid confusion with IP addresses - see https://stackoverflow.com/a/53875771 for further details
                          - or for testing purposes / hidden feature a local camera /dev/XXX is  supported
                           */
                          pattern: /^(\/dev\/\w+)$|^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})$|^((([a-zA-Z]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9-]*[A-Za-z0-9]))$/
                        })}
                      />
                      <FormLabelWithTooltip id="configuration.group.cameraConnection.port" />
                      <TextInput
                        id={
                          'configuration.group.cameraConnection.port.' +
                          stream.id
                        }
                        disabled={false}
                        invalid={
                          errors['cameraConfig'] &&
                          errors['cameraConfig']['port']
                        }
                        invalidText={t(
                          'configuration.group.cameraConnection.port.invalidText'
                        )}
                        labelText={false}
                        placeholder={t(
                          'configuration.group.cameraConnection.port.placeholder'
                        )}
                        name="cameraConfig.port"
                        defaultValue={cameraConfig ? cameraConfig.port : null}
                        ref={register({ pattern: /^\d+$/ })}
                      />
                      <FormLabelWithTooltip id="configuration.group.cameraConnection.path" />
                      <TextInput
                        id={
                          'configuration.group.cameraConnection.path.' +
                          stream.id
                        }
                        disabled={false}
                        invalid={errors['cameraConfig.path'] !== undefined}
                        invalidText={t('configuration.invalidText')}
                        labelText={false}
                        placeholder={t(
                          'configuration.group.cameraConnection.path.placeholder'
                        )}
                        name="cameraConfig.path"
                        defaultValue={cameraConfig ? cameraConfig.path : null}
                        ref={register}
                      />
                      <FormLabelWithTooltip id="configuration.group.cameraConnection.username" />
                      <TextInput
                        id={
                          'configuration.group.cameraConnection.username.' +
                          stream.id
                        }
                        disabled={false}
                        invalid={
                          errors['cameraConfig'] &&
                          errors['cameraConfig']['username']
                        }
                        invalidText={t(
                          'configuration.group.cameraConnection.username.invalidText'
                        )}
                        labelText={false}
                        placeholder={t(
                          'configuration.group.cameraConnection.username.placeholder'
                        )}
                        name="cameraConfig.username"
                        defaultValue={
                          cameraConfig ? cameraConfig.username : null
                        }
                        ref={register({ required: false, pattern: /^[^@]*$/ })}
                      />
                      <FormLabelWithTooltip id="configuration.group.cameraConnection.password" />
                      <TextInput
                        id={
                          'configuration.group.cameraConnection.password.' +
                          stream.id
                        }
                        disabled={false}
                        invalid={
                          errors['cameraConfig'] &&
                          errors['cameraConfig']['password']
                        }
                        invalidText={t(
                          'configuration.group.cameraConnection.password.invalidText'
                        )}
                        labelText={false}
                        placeholder={t(
                          'configuration.group.cameraConnection.password.placeholder'
                        )}
                        autoComplete="new-password"
                        type="password"
                        name="cameraConfig.password"
                        defaultValue={
                          cameraConfig ? cameraConfig.password : null
                        }
                        ref={register({ required: false, pattern: /^[^@]*$/ })}
                      />
                    </>
                  )}
                </AntForm>
              )}
              <AntForm className="scc--ioconnection">
                <Row className="scc--configheader">
                  <Col>
                    <ControlOutlined className="scc--icon--api" />
                  </Col>
                  <Col>
                    <div className="scc--headerlabel">
                      {t('configuration.group.advanced.legendText')}
                    </div>
                  </Col>
                </Row>
                <FormLabelWithTooltip id="configuration.group.advanced.trackCalibrationRecording" />
                <div>
                  <Space>
                    <Switch
                      key={
                        'configuration.group.advanced.recordTrackCalibration.' +
                        stream.id
                      }
                      checkedChildren={t('draw.toggle.on')}
                      unCheckedChildren={t('draw.toggle.off')}
                      checked={recordTrackCalibration}
                      onChange={(checked) =>
                        checked
                          ? setTrackCalibrationConfirmDialogVisible(true)
                          : setRecordTrackCalibration(false)
                      }
                    />
                  </Space>
                </div>
                <FormLabelWithTooltip id="configuration.group.advanced.debug" />
                <div>
                  <Space>
                    <Switch
                      key={'configuration.group.advanced.debug.' + stream.id}
                      checkedChildren={t('draw.toggle.on')}
                      unCheckedChildren={t('draw.toggle.off')}
                      checked={debugEnabled}
                      onChange={(checked) =>
                        checked && !stream.debug
                          ? setDebugConfirmDialogVisible(true)
                          : setDebugEnabled(checked)
                      }
                    />
                    {debugEnabled && (
                      <Alert
                        message={t(
                          'configuration.group.advanced.debug.enabledWarning'
                        )}
                        type="warning"
                        showIcon
                      />
                    )}
                  </Space>
                </div>
              </AntForm>
            </div>
            <div className="bx--col-lg-8">
              {mqttConfig && (
                <AntForm className="scc--ioconnection">
                  <Row className="scc--configheader">
                    <Col>
                      <UploadOutlined className="scc--icon--api" />
                    </Col>
                    <Col>
                      <div className="scc--headerlabel">
                        {t('configuration.group.mqttConnection.legendText')}
                      </div>
                    </Col>
                  </Row>
                  <FormLabelWithTooltip id="configuration.group.mqttConnection.configurationType" />
                  <Row className="scc--carbonfield-row">
                    <Checkbox.Group
                      options={getOptions()}
                      defaultValue={eventConnectionDefault}
                      onChange={handleEventConnectionConfigurationTypeChange}
                      key={
                        'configuration.group.mqttConnection.configurationType.' +
                        stream.id
                      }
                    />
                  </Row>
                  {customMqttConfig && (
                    <FormGroup
                      legendText={t(
                        'configuration.group.mqttConnection.mqtt.legendText'
                      )}
                    >
                      <FormLabelWithTooltip id="configuration.group.mqttConnection.mqtt.broker" />
                      <TextInput
                        id={
                          'configuration.group.mqttConnection.mqtt.broker.' +
                          stream.id
                        }
                        disabled={false}
                        invalid={
                          errors['mqttConfig'] && errors['mqttConfig']['host']
                        }
                        invalidText={t(
                          'configuration.group.mqttConnection.mqtt.broker.invalidText'
                        )}
                        labelText={false}
                        placeholder={t(
                          'configuration.group.mqttConnection.mqtt.broker.placeholder'
                        )}
                        name="mqttConfig.host"
                        defaultValue={customMqttConfig.host}
                        ref={register({ required: true })}
                      />
                      <FormLabelWithTooltip id="configuration.group.mqttConnection.mqtt.port" />
                      <TextInput
                        id={
                          'configuration.group.mqttConnection.mqtt.port.' +
                          stream.id
                        }
                        disabled={false}
                        invalid={
                          errors['mqttConfig'] && errors['mqttConfig']['port']
                        }
                        invalidText={t(
                          'configuration.group.mqttConnection.mqtt.port.invalidText'
                        )}
                        labelText={false}
                        placeholder={t(
                          'configuration.group.mqttConnection.mqtt.port.placeholder'
                        )}
                        name="mqttConfig.port"
                        defaultValue={customMqttConfig.port}
                        ref={register({ pattern: /^\d+$/ })}
                      />
                      <FormLabelWithTooltip id="configuration.group.mqttConnection.mqtt.topic" />
                      <TextInput
                        id={
                          'configuration.group.mqttConnection.mqtt.topic.' +
                          stream.id
                        }
                        disabled={false}
                        invalid={errors['mqttConfig.topic'] !== undefined}
                        invalidText={t('configuration.invalidText')}
                        labelText={false}
                        placeholder={t(
                          'configuration.group.mqttConnection.mqtt.topic.placeholder'
                        )}
                        name="mqttConfig.topic"
                        defaultValue={customMqttConfig.topic}
                        ref={register}
                      />
                      <FormLabelWithTooltip id="configuration.group.mqttConnection.mqtt.username" />
                      <TextInput
                        id={
                          'configuration.group.mqttConnection.mqtt.username.' +
                          stream.id
                        }
                        disabled={false}
                        invalid={
                          errors['mqttConfig'] &&
                          errors['mqttConfig']['username']
                        }
                        invalidText={t(
                          'configuration.group.mqttConnection.mqtt.username.invalidText'
                        )}
                        labelText={false}
                        placeholder={t(
                          'configuration.group.mqttConnection.mqtt.username.placeholder'
                        )}
                        name="mqttConfig.username"
                        defaultValue={customMqttConfig.username}
                        ref={register({ required: false, pattern: /^[^@]*$/ })}
                      />
                      <FormLabelWithTooltip id="configuration.group.mqttConnection.mqtt.password" />
                      <TextInput
                        id={
                          'configuration.group.mqttConnection.mqtt.password.' +
                          stream.id
                        }
                        disabled={false}
                        invalid={
                          errors['mqttConfig'] &&
                          errors['mqttConfig']['password']
                        }
                        invalidText={t(
                          'configuration.group.mqttConnection.mqtt.password.invalidText'
                        )}
                        labelText={false}
                        placeholder={t(
                          'configuration.group.mqttConnection.mqtt.password.placeholder'
                        )}
                        autoComplete="new-password"
                        type="password"
                        name="mqttConfig.password"
                        defaultValue={customMqttConfig.password}
                        ref={register({ required: false, pattern: /^[^@]*$/ })}
                      />
                      <FormLabelWithTooltip id="configuration.group.mqttConnection.mqtt.clientId" />
                      <TextInput
                        id={
                          'configuration.group.mqttConnection.mqtt.clientId.' +
                          stream.id
                        }
                        disabled={false}
                        invalid={
                          errors['mqttConfig'] &&
                          errors['mqttConfig']['clientId']
                        }
                        invalidText={t(
                          'configuration.group.mqttConnection.mqtt.clientId.invalidText'
                        )}
                        labelText={false}
                        placeholder={stream.id}
                        name="mqttConfig.clientId"
                        defaultValue={customMqttConfig.clientId}
                        ref={register({
                          required: false,
                          pattern: /^[a-zA-Z0-9_-]*$/
                        })}
                      />
                      <FormLabelWithTooltip id="configuration.group.mqttConnection.mqtt.qos" />
                      {
                        <Select
                          id={
                            'configuration.group.mqttConnection.mqtt.qos.' +
                            stream.id
                          }
                          className="scc--formfield__value-set"
                          inline={false}
                          labelText={false}
                          invalidText={t('configuration.invalidText')}
                          name="mqttConfig.qos"
                          value={customMqttConfig.qos}
                          onChange={(data) => {
                            handleMqttQosChanged(Number(data.target.value))
                          }}
                        >
                          {Object.values(EMqttQos)
                            .filter((item) => isNaN(Number(item)))
                            .map((item, value) => (
                              <SelectItem
                                key={item}
                                value={value}
                                text={t(
                                  `configuration.group.mqttConnection.mqtt.qos.values.${item}`
                                )}
                              />
                            ))}
                        </Select>
                      }
                      <FormLabelWithTooltip id="configuration.group.mqttConnection.mqtt.compression" />
                      <div>
                        <Switch
                          checkedChildren={t('draw.toggle.on')}
                          unCheckedChildren={t('draw.toggle.off')}
                          checked={customMqttConfig.compression}
                          onChange={handleMqttCompressionChanged}
                        />
                      </div>
                    </FormGroup>
                  )}
                </AntForm>
              )}
              {
                <AntForm className="scc--ioconnection">
                  <Row className="scc--configheader">
                    <Col>
                      <ApiOutlined className="scc--icon--api" />
                    </Col>
                    <Col>
                      <div className="scc--headerlabel">
                        {t('configuration.group.ioConnection.legendText')}
                      </div>
                    </Col>
                  </Row>
                  <Row className="scc--carbonfield-row">
                    <Checkbox.Group
                      options={getIOOptions()}
                      defaultValue={eventConnectionDefault}
                      onChange={handleIoTriggerConfigurationTypeChange}
                      key={
                        'configuration.group.mqttConnection.configurationType.' +
                        stream.id
                      }
                    />
                  </Row>
                  {ioTriggerConfig && (
                    <FormGroup
                      legendText={t(
                        'configuration.group.mqttConnection.ioDevice.legendText'
                      )}
                    >
                      <Alert
                        message={t(
                          'configuration.group.mqttConnection.ioDevice.description'
                        )}
                        type="info"
                        showIcon
                      />
                      <FormLabelWithTooltip id="configuration.group.mqttConnection.ioDevice.ioDeviceType" />
                      <Select
                        id={
                          'configuration.group.mqttConnection.ioDevice.ioDeviceType.' +
                          stream.id
                        }
                        labelText={false}
                        name="ioTriggerConfig.configurationType"
                        onChange={(data) => {
                          const newConfigType = data.target
                            .value as EIoTriggerConfigurationType

                          handleIoTriggerConfigChanged({
                            ...ioTriggerConfig,
                            host:
                              newConfigType ===
                              EIoTriggerConfigurationType.quido
                                ? ioTriggerConfig.host
                                : undefined,
                            quidoDeviceType:
                              newConfigType ===
                              EIoTriggerConfigurationType.quido
                                ? ioTriggerConfig.quidoDeviceType
                                : undefined,
                            configurationType: data.target.value
                          })
                        }}
                        value={ioTriggerConfig.configurationType}
                      >
                        {Object.values(EIoTriggerConfigurationType).map(
                          (item) => {
                            if (
                              item === EIoTriggerConfigurationType.bmc &&
                              !isBmcAvailable
                            ) {
                              // Skip rendering the BMC item if isBmcAvailable is false
                              return null
                            }

                            // Render the SelectItem for all other cases
                            return (
                              <SelectItem
                                key={item}
                                value={item}
                                text={t(
                                  `configuration.group.mqttConnection.ioDevice.ioDeviceType.values.${item}`
                                )}
                              />
                            )
                          }
                        )}
                      </Select>
                      {ioTriggerConfig.configurationType &&
                        ioTriggerConfig.configurationType ===
                          EIoTriggerConfigurationType.quido && (
                          <>
                            <FormLabelWithTooltip id="configuration.group.mqttConnection.ioDevice.ioDeviceModel" />
                            <Select
                              id={
                                'configuration.group.mqttConnection.ioDevice.ioDeviceModel.' +
                                stream.id
                              }
                              className="scc--formfield__value-set"
                              inline={false}
                              labelText={false}
                              invalidText={t(
                                'configuration.group.mqttConnection.ioDevice.ioDeviceModel.invalidText'
                              )}
                              name="ioTriggerConfig.ioDeviceModel"
                              onChange={(data) => {
                                handleIoTriggerConfigChanged({
                                  ...ioTriggerConfig,
                                  quidoDeviceType: data.target.value
                                })
                              }}
                              invalid={
                                errors['ioTriggerConfig'] &&
                                errors['ioTriggerConfig']['quidoDeviceType']
                              }
                              ref={register({
                                required:
                                  ioTriggerConfig.configurationType &&
                                  ioTriggerConfig.configurationType ===
                                    EIoTriggerConfigurationType.quido
                              })}
                              value={ioTriggerConfig.quidoDeviceType}
                            >
                              <SelectItem
                                value=""
                                text={t(
                                  'configuration.group.mqttConnection.ioDevice.ioDeviceModel.placeholder'
                                )}
                              />
                              {Object.values(EQuidoDeviceType).map((item) => (
                                <SelectItem
                                  key={item}
                                  value={item}
                                  text={t(
                                    `configuration.group.mqttConnection.ioDevice.ioDeviceModel.values.${item}`
                                  )}
                                />
                              ))}
                            </Select>

                            <FormLabelWithTooltip id="configuration.group.mqttConnection.ioDevice.endpoint" />
                            <TextInput
                              id={
                                'configuration.group.mqttConnection.ioDevice.endpoint.' +
                                stream.id
                              }
                              disabled={false}
                              invalid={
                                errors['ioTriggerConfig'] &&
                                errors['ioTriggerConfig']['host']
                              }
                              invalidText={t(
                                'configuration.group.mqttConnection.ioDevice.endpoint.invalidText'
                              )}
                              labelText={false}
                              placeholder={t(
                                'configuration.group.mqttConnection.ioDevice.endpoint.placeholder'
                              )}
                              name="ioTriggerConfig.host"
                              defaultValue={ioTriggerConfig.host}
                              ref={register({ required: true })}
                            />
                          </>
                        )}
                    </FormGroup>
                  )}
                </AntForm>
              }
            </div>
          </div>
          <Row>
            <Col flex="auto">
              <Button
                disabled={props.isSubmitting}
                kind="primary"
                size="small"
                type="submit"
              >
                {t('configuration.saveStream')}
              </Button>
            </Col>
            <Col flex="none">
              {!props.isNew && (
                <AntdButton
                  disabled={props.isSubmitting}
                  size="large"
                  className="scc--stream--delete-button"
                  type="default"
                  onClick={onDelete}
                >
                  {t('configuration.deleteStream')}
                </AntdButton>
              )}
            </Col>
          </Row>
        </Form>
        <LocationPickerModal
          key={stream.id + 'locationPicker'}
          streamCoordinates={coordinates}
          setStreamCoordinates={(coordinates) => {
            setCoordinates(coordinates)
            checkCoordinates(coordinates.latitude, coordinates.longitude)
          }}
          userSelectedCoordinates={userSelectedCoordinates}
          setUserSelectedCoordinates={setUserSelectedCoordinates}
          show={showLocationPicker}
          close={(e) => setShowLocationPicker(false)}
          resetView={resetView}
          setResetView={setResetView}
          title={'configuration.group.streamMeta.coordinates.picker.title'}
        />
      </AccordionItem>
      <ConfirmationDialog
        primaryButtonText={t('modal.debug.primaryButton.title')}
        secondaryButtonText={t('modal.debug.secondaryButton.title')}
        onRequestClose={() => setDebugConfirmDialogVisible(false)}
        onRequestSubmit={() => {
          setDebugEnabled(true)
          setDebugConfirmDialogVisible(false)
        }}
        onSecondarySubmit={() => setDebugConfirmDialogVisible(false)}
        danger={true}
        size="xs"
        open={debugConfirmDialogVisible}
      >
        <p dangerouslySetInnerHTML={{ __html: t('modal.debug.title') }} />
      </ConfirmationDialog>
      <ConfirmationDialog
        primaryButtonText={t('modal.trackCalibration.primaryButton.title')}
        secondaryButtonText={t('modal.trackCalibration.secondaryButton.title')}
        onRequestClose={() => setTrackCalibrationConfirmDialogVisible(false)}
        onRequestSubmit={() => {
          setRecordTrackCalibration(true)
          setTrackCalibrationConfirmDialogVisible(false)
        }}
        onSecondarySubmit={() => setTrackCalibrationConfirmDialogVisible(false)}
        danger={true}
        size="xs"
        open={trackCalibrationConfirmDialogVisible}
      >
        <p>{t('modal.trackCalibration.title')}</p>
      </ConfirmationDialog>
    </>
  )
}

export default StreamConfigurationForm
