import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Col, Form, Input, Modal, Select, Space } from 'antd'
import { Utils as QbUtils } from 'react-awesome-query-builder'

import 'react-awesome-query-builder/lib/css/styles.css'
import {
  EEventTriggerType,
  ICoordinateBasedEventTrigger
} from '../../types/eventTrigger'
import {
  ITriggerRule,
  ITriggerRuleCondition,
  ITriggerRuleTemplate,
  TriggerRule
} from '../../types/triggerRule'
import TriggerRuleQuery, {
  getTreeFromJsonLogic,
  State
} from './Helper/TriggerRuleQuery'
import { getConfig } from './config'
import { IStream } from '../../types/stream'
import FormLabelWithTooltip from '../FormLabelWithToolTip'

interface ICreateRuleModalProps {
  eventTriggers: ICoordinateBasedEventTrigger[]
  show: boolean
  close: Function
  upsertRule: Function
  ruleToEdit: ITriggerRule | null
  userTemplates: ITriggerRuleTemplate[]
  stream: IStream
}

interface ITriggerSelection {
  triggerType: EEventTriggerType
  trigger?: string
}

const CreateRuleModal: React.FC<ICreateRuleModalProps> = ({
  show,
  close,
  eventTriggers,
  upsertRule,
  ruleToEdit,
  userTemplates,
  stream
}) => {
  const { t } = useTranslation()

  const [numConditions, setNumConditions] = useState<number>(1)
  const [newRuleName, setNewRuleName] = useState<string>('')
  const [invalidRuleName, setInvalidRuleName] = useState(false)
  const [invalidTriggerSelections, setInvalidTriggerSelections] = useState<
    boolean[]
  >([])
  const [emptyQueries, setEmptyQueries] = useState<boolean[]>([])
  const [queryStates, setQueryStates] = useState<(State | undefined)[]>([])
  const [
    invalidIoTriggerSelection,
    setInvalidIoTriggerSelection
  ] = useState<boolean>(false)
  const [triggerSelections, setTriggerSelections] = useState<
    (ITriggerSelection | undefined)[]
  >([])
  const [ioPinSelected, setIoPinSelected] = useState<number | undefined>(
    ruleToEdit?.ioPin
  )
  const [validTriggerTypes, setVaildTriggerTypes] = useState<
    EEventTriggerType[][]
  >([
    [
      EEventTriggerType.originDestinationZone,
      EEventTriggerType.regionOfInterest,
      EEventTriggerType.crossingLine,
      EEventTriggerType.virtualDoor
    ]
  ])
  let ruleNameInput

  const putStateOfQuery = useCallback(
    (index: number, data) => {
      let newQueryStates = [...queryStates]
      newQueryStates[index] = data
      setQueryStates(newQueryStates)
    },
    [queryStates]
  )

  const putTriggerSelection = useCallback(
    (index: number, data) => {
      let newTriggerSelections = [...triggerSelections]
      newTriggerSelections[index] = data
      setTriggerSelections(newTriggerSelections)
      if (
        newTriggerSelections[index]?.triggerType !==
        EEventTriggerType.regionOfInterest
      ) {
        setIoPinSelected(undefined)
      }
    },
    [triggerSelections]
  )

  const setInvalidTriggerSelection = useCallback(
    (index: number, data) => {
      let newInvalidTriggerSelections = [...invalidTriggerSelections]
      newInvalidTriggerSelections[index] = data
      setInvalidTriggerSelections(newInvalidTriggerSelections)
    },
    [invalidTriggerSelections]
  )

  const setEmptyQuery = useCallback(
    (index: number, data) => {
      let newQueries = [...emptyQueries]
      newQueries[index] = data
      setEmptyQueries(newQueries)
    },
    [emptyQueries]
  )

  useEffect(() => {
    if (ruleToEdit) {
      setNumConditions(ruleToEdit.conditions.length)
      for (let i = 0; i < ruleToEdit.conditions.length; i++) {
        let conditionToEdit = ruleToEdit.conditions[i]
        triggerSelections.push(conditionToEdit as ITriggerSelection)

        if (conditionToEdit.query && conditionToEdit.query.length) {
          let config = getConfig(
            conditionToEdit.trigger,
            conditionToEdit.triggerType,
            eventTriggers
          )
          queryStates.push({
            tree: getTreeFromJsonLogic(conditionToEdit.query, config),
            config: config
          })
        }

        setIoPinSelected(ruleToEdit.ioPin)

        setInvalidTriggerSelection(i, false)
        setEmptyQuery(i, false)
      }
      // lock the triggertype for each condition to it's current type in rule for combined rules
      if (ruleToEdit.conditions.length === 2) {
        setVaildTriggerTypes([
          [ruleToEdit.conditions[0].triggerType],
          [ruleToEdit.conditions[1].triggerType]
        ])
      }
      setNewRuleName(ruleToEdit.name)
      setInvalidRuleName(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleToEdit])

  const handleRuleNameChange = (event) => {
    setNewRuleName(event.target.value)
    if (!event.target.value || !event.target.value.length) {
      setInvalidRuleName(true)
    } else {
      setInvalidRuleName(false)
    }
  }

  const handleIoPinSelectionChanged = (value) => {
    if (
      !isNaN(value) &&
      stream.ioOutPins &&
      value > 0 &&
      value <= stream.ioOutPins
    ) {
      invalidIoTriggerSelection && setInvalidIoTriggerSelection(false)
    }
    setIoPinSelected(value)
  }

  const validateFields = (conditions) => {
    let error = false
    if (!newRuleName || !newRuleName.length) {
      ruleNameInput.focus()
      setInvalidRuleName(true)
      error = true
    } else {
      invalidRuleName && setInvalidRuleName(false)
    }

    for (let i = 0; i < conditions.length; i++) {
      if (!conditions[i].triggerType) {
        setInvalidTriggerSelection(i, true)
        error = true
      }
      if (!conditions[i].query || !conditions[i].query.length) {
        setEmptyQuery(i, true)
        error = true
      }
    }
    if (
      ioPinSelected &&
      (ioPinSelected < 0 ||
        !stream.ioOutPins ||
        ioPinSelected > stream.ioOutPins)
    ) {
      setInvalidIoTriggerSelection(true)
      error = true
    } else {
      invalidIoTriggerSelection && setInvalidIoTriggerSelection(false)
    }

    return !error
  }

  const handleSave = () => {
    let conditions: ITriggerRuleCondition[] = []
    for (let i = 0; i < numConditions; i++) {
      let state = queryStates[i]
      if (state) {
        const { logic } = QbUtils.jsonLogicFormat(state.tree, state.config)
        conditions.push({
          ...triggerSelections[i]!,
          query: JSON.stringify(logic)
        })
      } else {
        conditions.push({ ...triggerSelections[i]!, query: '' })
      }
    }
    if (!validateFields(conditions)) {
      return
    }
    upsertRule(
      new TriggerRule({
        localId: ruleToEdit ? ruleToEdit.localId : undefined,
        name: newRuleName,
        conditions: conditions,
        template: false,
        hasChanged: true,
        ioPin: ioPinSelected
      })
    )
    handleCancel()
  }

  const handleCancel = () => {
    setNewRuleName('')
    setTriggerSelections([])
    setQueryStates([])
    setNumConditions(1)
    setInvalidTriggerSelections([])
    setEmptyQueries([])
    setVaildTriggerTypes([
      [
        EEventTriggerType.virtualDoor,
        EEventTriggerType.crossingLine,
        EEventTriggerType.regionOfInterest,
        EEventTriggerType.originDestinationZone
      ]
    ])
    close()
  }

  const allowAddCondition = () => {
    return (
      numConditions === 1 &&
      (triggerSelections[0]?.triggerType === EEventTriggerType.crossingLine ||
        triggerSelections[0]?.triggerType ===
          EEventTriggerType.regionOfInterest)
    )
  }

  const addCondition = () => {
    if (!allowAddCondition()) {
      return
    }
    setIoPinSelected(undefined)
    setVaildTriggerTypes([
      [triggerSelections[0]!.triggerType],
      [
        triggerSelections[0]!.triggerType === EEventTriggerType.crossingLine
          ? EEventTriggerType.regionOfInterest
          : EEventTriggerType.crossingLine
      ]
    ])
    setNumConditions((prev) => prev + 1)
  }

  const removeLastCondition = () => {
    setTriggerSelections((prev) => [...prev].slice(0, numConditions))
    setQueryStates((prev) => [...prev].slice(0, numConditions))
    setInvalidTriggerSelections((prev) => [...prev].slice(0, numConditions))
    setEmptyQueries((prev) => [...prev].slice(0, numConditions))
    setVaildTriggerTypes([
      [
        EEventTriggerType.virtualDoor,
        EEventTriggerType.crossingLine,
        EEventTriggerType.regionOfInterest,
        EEventTriggerType.originDestinationZone
      ]
    ])
    setNumConditions((prev) => prev - 1)
    if (
      triggerSelections[0]?.triggerType === EEventTriggerType.regionOfInterest
    ) {
      setIoPinSelected(ruleToEdit?.ioPin)
    }
  }

  return (
    <Modal
      title={
        <Col span={12}>
          <Form.Item validateStatus={invalidRuleName ? 'error' : ''}>
            <Input
              size="large"
              placeholder="Rule name"
              value={newRuleName}
              onChange={handleRuleNameChange}
              ref={(input) => {
                ruleNameInput = input
              }}
            />
          </Form.Item>
        </Col>
      }
      visible={show}
      onCancel={handleCancel}
      width={1000}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {t('draw.ruleEngine.add.cancel')}
        </Button>,
        <Button type="primary" key="save" onClick={handleSave}>
          {t('draw.ruleEngine.add.save')}
        </Button>
      ]}
    >
      <p>{t('draw.ruleEngine.add.headline')}</p>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        {[...Array(numConditions)].map((e, i) => (
          <TriggerRuleQuery
            eventTriggers={eventTriggers}
            userTemplates={userTemplates}
            key={i}
            triggerSelection={triggerSelections[i]}
            setTriggerSelection={(state) => {
              putTriggerSelection(i, state)
            }}
            queryState={queryStates[i]}
            setQueryState={(state) => {
              putStateOfQuery(i, state)
            }}
            invalidTriggerSelection={invalidTriggerSelections[i]}
            setInvalidTriggerSelection={(state) => {
              setInvalidTriggerSelection(i, state)
            }}
            emptyQuery={emptyQueries[i]}
            setEmptyQuery={setEmptyQuery}
            validTriggerTypes={validTriggerTypes[i]}
          />
        ))}
        {numConditions > 1 && (
          <Alert
            message={t('draw.ruleEngine.add.clRoiIntervalInfo')}
            type="info"
            showIcon={true}
          />
        )}
        {numConditions === 1 &&
          (triggerSelections[0]?.triggerType ===
            EEventTriggerType.regionOfInterest ||
            triggerSelections[0]?.triggerType ===
              EEventTriggerType.crossingLine) && (
            <>
              {stream && stream.ioOutPins && stream.ioOutPins > 0 && (
                <>
                  <div className={'scc--tooltip-rule-engine'}>
                    <FormLabelWithTooltip
                      id={'draw.ruleEngine.add.ioDeviceOutput'}
                    />
                  </div>
                  <Form.Item>
                    <Select
                      key={'ioPinSelection'}
                      value={ioPinSelected}
                      className={
                        invalidIoTriggerSelection
                          ? 'scc--rule-engine--iopin-invalid'
                          : ''
                      }
                      // options range from 1 to the number of ioOutPins
                      onChange={handleIoPinSelectionChanged}
                      allowClear={true}
                      placeholder={t(
                        'draw.ruleEngine.add.ioDeviceOutput.placeholder'
                      )}
                    >
                      {[...Array(stream.ioOutPins)].map((e, i) => (
                        <Select.Option value={i + 1} key={'iopinoption' + i}>
                          {i + 1}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              )}
              <Alert
                message={t('draw.ruleEngine.add.roiIntervalInfo')}
                type="info"
                showIcon={true}
              />
            </>
          )}
      </Space>
      <Button
        className={'scc--btn--rule-engine-condition'}
        hidden={!allowAddCondition()}
        type="primary"
        key="addCondition"
        onClick={addCondition}
      >
        {t('draw.ruleEngine.add.addCondition')}
      </Button>
      <Button
        className={'scc--btn--rule-engine-condition'}
        hidden={numConditions <= 1}
        key="removeCondition"
        onClick={removeLastCondition}
      >
        {t('draw.ruleEngine.add.removeCondition')}
      </Button>
    </Modal>
  )
}

export default CreateRuleModal
