import './DrawContextForm.scss'
import '../ConfirmDialog/ConfirmationDialog.scss'

import { Form } from 'carbon-components-react'
import React, { useEffect, useState } from 'react'

import { EBoxModel } from '../../types/boxModel'
import SceneSetup from './SceneSetup'
import { HeatMapEventTrigger } from '../../types/heatMap'
import {
  EEventTriggerType,
  ICoordinateBasedEventTrigger
} from '../../types/eventTrigger'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ERegionOfInterestTriggerType } from '../../types/regionOfInterest'
import { AnprEventTrigger } from '../../types/anpr'
import ConfirmationDialog from '../ConfirmDialog/ConfirmationDialog'
import { JourneyTimeEventTrigger } from '../../types/journeyTime'
import { ICoordinates } from '../../types/stream'
import LocationPickerModal from '../LocationPicker/LocationPickerModal'
import { Button } from 'antd'
import { GdprEventTrigger } from '../../types/gdpr'

interface IDrawContextFormProps {
  eventTrigger?: ICoordinateBasedEventTrigger
  heatMap?: HeatMapEventTrigger
  anpr?: AnprEventTrigger
  gdpr?: GdprEventTrigger
  journeyTime?: JourneyTimeEventTrigger
  shouldShowLPMode: boolean
  anprOptionaAvailable: boolean
  anonymisedAnprOptionAvailable: boolean
  enableRoiInterval: boolean
  hasRuleAttached: boolean
  onButtonClick: (MouseEvent, EEventTriggerType) => void
  onValueChanged: (IEventTrigger, Object) => void
  onToggleDirection: (MouseEvent, IEventTrigger) => void
  onToggleSpeedEstimate: (MouseEvent, IEventTrigger, boolean) => void
  model: EBoxModel | null
  coordinates: ICoordinates | undefined
  roiInterval: number
  isSubmitting: boolean
  hasDataChanged: boolean
  onSubmit: any
  enableFocusArea: boolean
  attachLicensePlateImage: boolean
  hasCustomMqttConfig: boolean
  onToggleAttachLicensePlateImage: (boolean) => void
  onDeleteAll: () => void
}

const DrawContextForm: React.FC<IDrawContextFormProps> = ({
  eventTrigger,
  hasRuleAttached,
  heatMap,
  anpr,
  gdpr,
  journeyTime,
  shouldShowLPMode,
  anprOptionaAvailable,
  anonymisedAnprOptionAvailable,
  enableRoiInterval,
  onButtonClick,
  onValueChanged,
  onToggleDirection,
  onToggleSpeedEstimate,
  model,
  coordinates,
  roiInterval,
  isSubmitting,
  hasDataChanged,
  onSubmit,
  enableFocusArea,
  attachLicensePlateImage,
  hasCustomMqttConfig,
  onToggleAttachLicensePlateImage,
  onDeleteAll
}) => {
  const { t } = useTranslation()
  const { register, reset, getValues, handleSubmit, errors } = useForm()

  const [lpmode, setlpmode] = useState<EEventTriggerType | undefined>(undefined)

  let currentLpMode: EEventTriggerType | undefined = undefined
  if (journeyTime && journeyTime.enabled) {
    currentLpMode = EEventTriggerType.journeyTime
  }
  if (anpr && anpr.enabled) {
    currentLpMode = EEventTriggerType.anpr
  }
  if (gdpr && gdpr.enabled) {
    currentLpMode = EEventTriggerType.gdpr
  }

  useEffect(() => {
    reset({ ...eventTrigger })
    if (currentLpMode) {
      setlpmode(currentLpMode)
    }
  }, [eventTrigger, reset, currentLpMode])

  const [userSelectedCoordinates, setUserSelectedCoordinates] = useState<
    ICoordinates | undefined
  >()
  const [showLocationPicker, setShowLocationPicker] = useState<boolean>(false)
  const [
    resetLocationPickerView,
    setResetLocationPickerView
  ] = useState<boolean>(true)

  const [
    anprConfirmationDialogVisible,
    setAnprConfirmationDialogVisible
  ] = useState<boolean>(false)

  const valueChanged = (event) => {
    onValueChanged(eventTrigger, getValues())
  }

  const anprStorageDurationChanged = (event) => {
    let newValue = event.imaginaryTarget.value
    if (!newValue) newValue = null
    onValueChanged(anpr, {
      eventTrigger: Object.assign({}, anpr, { anprStorageTime: newValue })
    })
  }

  const onToggleRoiTrigger = (e) => {
    onValueChanged(eventTrigger, {
      eventTrigger: Object.assign({}, eventTrigger, {
        trigger: e.target.value
      })
    })
  }

  const onToggleRoiType = (e) => {
    onValueChanged(eventTrigger, {
      eventTrigger: Object.assign({}, eventTrigger, {
        roiType: e.target.value,
        trigger: ERegionOfInterestTriggerType.time
      })
    })
  }

  const onChangeRoiCapacity = (event) => {
    let newValue = event.imaginaryTarget.value
    if (!newValue) newValue = null
    onValueChanged(eventTrigger, {
      eventTrigger: Object.assign({}, eventTrigger, { capacity: newValue })
    })
  }

  const onToggleSpeed = (checked, id, evt) => {
    onToggleSpeedEstimate(evt, eventTrigger, checked)
  }

  // Notifies the parent container about the changes
  const onToggleHeatmap = (checked, id, evt) => {
    onValueChanged(heatMap, {
      eventTrigger: Object.assign({}, heatMap, { enabled: checked })
    })
  }

  const onToggleLPMode = (mode) => {
    if (mode === EEventTriggerType.anpr) {
      setAnprConfirmationDialogVisible(true)
    } else if (mode === EEventTriggerType.journeyTime) {
      if (coordinates) {
        onValueChanged(journeyTime, {
          eventTrigger: Object.assign({}, journeyTime, { enabled: true })
        })
        onValueChanged(anpr, {
          eventTrigger: Object.assign({}, anpr, { enabled: false })
        })
        onValueChanged(gdpr, {
          eventTrigger: Object.assign({}, gdpr, { enabled: false })
        })
        setlpmode(EEventTriggerType.journeyTime)
        onToggleAttachLicensePlateImage(false)
      } else {
        setShowLocationPicker(true)
      }
    } else if (mode === EEventTriggerType.gdpr) {
      onValueChanged(journeyTime, {
        eventTrigger: Object.assign({}, journeyTime, { enabled: false })
      })
      onValueChanged(anpr, {
        eventTrigger: Object.assign({}, anpr, { enabled: false })
      })
      onValueChanged(gdpr, {
        eventTrigger: Object.assign({}, gdpr, { enabled: true })
      })
      setlpmode(EEventTriggerType.gdpr)
      onToggleAttachLicensePlateImage(false)
    } else {
      onValueChanged(journeyTime, {
        eventTrigger: Object.assign({}, journeyTime, { enabled: false })
      })
      onValueChanged(anpr, {
        eventTrigger: Object.assign({}, anpr, { enabled: false })
      })
      onValueChanged(gdpr, {
        eventTrigger: Object.assign({}, gdpr, { enabled: false })
      })
      onToggleAttachLicensePlateImage(false)
      setlpmode(EEventTriggerType.disabled)
    }
  }

  const onToggleMultipleCrossings = (checked, id, evt) => {
    onValueChanged(eventTrigger, {
      eventTrigger: Object.assign({}, eventTrigger, {
        multipleCrossings: checked
      })
    })
  }

  const onToggleAttachMovementPath = (checked, id, evt) => {
    onValueChanged(eventTrigger, {
      eventTrigger: Object.assign({}, eventTrigger, {
        attachMovementPath: checked
      })
    })
  }

  const confirmAnpr = () => {
    setAnprConfirmationDialogVisible(false)
    setlpmode(EEventTriggerType.anpr)
    onValueChanged(anpr, {
      eventTrigger: Object.assign({}, anpr, { enabled: true })
    })
    onValueChanged(journeyTime, {
      eventTrigger: Object.assign({}, journeyTime, { enabled: false })
    })
    onValueChanged(gdpr, {
      eventTrigger: Object.assign({}, gdpr, { enabled: false })
    })
  }

  const onCloseAnprConfirmation = () => {
    setAnprConfirmationDialogVisible(false)
  }

  const onCoordinatesSave = () => {
    setResetLocationPickerView(true)
    onValueChanged(coordinates, {
      coordinates: Object.assign({}, coordinates, {
        ...userSelectedCoordinates
      })
    })
    setlpmode(EEventTriggerType.journeyTime)
    onValueChanged(journeyTime, {
      eventTrigger: Object.assign({}, journeyTime, { enabled: true })
    })
    onValueChanged(anpr, {
      eventTrigger: Object.assign({}, anpr, { enabled: false })
    })
    onValueChanged(gdpr, {
      eventTrigger: Object.assign({}, gdpr, { enabled: false })
    })
    setShowLocationPicker(false)
  }

  return (
    <>
      <Form
        name="draw-config-form"
        className="draw-config-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SceneSetup
          register={register}
          onButtonClick={onButtonClick}
          onToggleHeatmap={onToggleHeatmap}
          anprStorageDurationChanged={anprStorageDurationChanged}
          shouldShowLPMode={shouldShowLPMode}
          anprOptionaAvailable={anprOptionaAvailable}
          anonymisedAnprOptionAvailable={anonymisedAnprOptionAvailable}
          enableRoiInterval={enableRoiInterval}
          onToggleDirection={onToggleDirection}
          onToggleAttachLicensePlateImage={onToggleAttachLicensePlateImage}
          hasCustomMqttConfig={hasCustomMqttConfig}
          onToggleSpeedEstimate={onToggleSpeed}
          onToggleMultipleCrossings={onToggleMultipleCrossings}
          onToggleAttachMovementPath={onToggleAttachMovementPath}
          onToggleRoiTrigger={onToggleRoiTrigger}
          onToggleRoiType={onToggleRoiType}
          onChangeRoiCapacity={onChangeRoiCapacity}
          onValueChanged={valueChanged}
          eventTrigger={eventTrigger}
          hasRuleAttached={hasRuleAttached}
          errors={errors}
          model={model}
          lpMode={lpmode}
          roiInterval={roiInterval}
          heatMap={heatMap}
          anpr={anpr}
          onLPModeChange={onToggleLPMode}
          enableFocusArea={enableFocusArea}
          attachLicensePlateImage={attachLicensePlateImage}
        />

        <div className="scc--sticky scc--boxcontextual--btn-save--container">
          <Button size={'large'} onClick={onDeleteAll}>
            {t('draw.buttonReset')}
          </Button>
          <button
            type="submit"
            disabled={isSubmitting || !hasDataChanged}
            className="bx--btn bx--btn--primary scc--boxcontextual--btn-save"
          >
            {t('draw.buttonSave.title')}
          </button>
        </div>
      </Form>
      <ConfirmationDialog
        modalHeading={t('draw.form.anpr.confirmation.heading')}
        primaryButtonText={t('draw.form.anpr.confirmation.primaryButton.title')}
        secondaryButtonText={t(
          'draw.form.anpr.confirmation.secondaryButton.title'
        )}
        onRequestClose={onCloseAnprConfirmation}
        onSecondarySubmit={onCloseAnprConfirmation}
        onRequestSubmit={confirmAnpr}
        open={anprConfirmationDialogVisible}
      >
        {t('draw.form.anpr.confirmation.text')}
      </ConfirmationDialog>
      <LocationPickerModal
        streamCoordinates={coordinates}
        setStreamCoordinates={(e) => (coordinates = e)}
        userSelectedCoordinates={userSelectedCoordinates}
        setUserSelectedCoordinates={setUserSelectedCoordinates}
        show={showLocationPicker}
        close={() => setShowLocationPicker(false)}
        resetView={resetLocationPickerView}
        setResetView={setResetLocationPickerView}
        save={onCoordinatesSave}
        title={'draw.form.lpMode.picker.title'}
      />{' '}
    </>
  )
}

export default DrawContextForm
