import { NumberInput, TextInput } from 'carbon-components-react'
import { CaretLeftGlyph, CaretRightGlyph } from '@carbon/icons-react'
import { CrossingLineEventTrigger } from '../../types/crossingLine'
import { EEventTriggerType } from '../../types/eventTrigger'
import FormLabelWithTooltip from '../FormLabelWithToolTip'
import { userIsAtLeast, UserRole } from '../../types/user_role'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ERegionOfInterestTriggerType,
  ERegionOfInterestType,
  isFocusArea
} from '../../types/regionOfInterest'
import { Button, Radio, Select, Space, Switch, Tooltip } from 'antd'

export const TriggerSettings = ({
  eventTrigger,
  hasRuleAttached,
  onToggleSpeedEstimate,
  onValueChanged,
  personModelSelected,
  onToggleRoiType,
  onToggleRoiTrigger,
  onChangeRoiCapacity,
  onToggleDirection,
  onToggleMultipleCrossings,
  onToggleAttachMovementPath,
  errors,
  register
}) => {
  const { t } = useTranslation()
  return (
    <div>
      <Space direction={'vertical'} size="large" style={{ width: '100%' }}>
        {eventTrigger &&
          eventTrigger.objectType === EEventTriggerType.regionOfInterest &&
          !isFocusArea(eventTrigger) && (
            <>
              <div>
                <FormLabelWithTooltip id="draw.form.roi.name" />
                <TextInput
                  id="draw.form.roi.name"
                  className="scc--formfield__value-set"
                  disabled={false}
                  invalid={false}
                  invalidText={t('draw.form.invalidText')}
                  labelText={false}
                  placeholder={t('draw.form.roi.name.placeholder')}
                  name="eventTrigger.name"
                  defaultValue={eventTrigger ? eventTrigger.name : ''}
                  onChange={onValueChanged}
                  ref={register}
                />
              </div>

              {!personModelSelected && (
                <div>
                  <FormLabelWithTooltip id="draw.form.roi.type" />
                  <div>
                    <Radio.Group
                      value={eventTrigger.roiType}
                      onChange={onToggleRoiType}
                      aria-label="RoI Type Toggle"
                      id="draw.form.roi.type"
                    >
                      <Radio.Button value={ERegionOfInterestType.generic}>
                        {t('draw.form.roi.type.values.generic')}
                      </Radio.Button>
                      <Radio.Button
                        value={ERegionOfInterestType.parking}
                        disabled={hasRuleAttached}
                      >
                        {t('draw.form.roi.type.values.parking')}
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                </div>
              )}

              {eventTrigger.roiType === ERegionOfInterestType.generic && (
                <>
                  <div>
                    <FormLabelWithTooltip id="draw.form.roi.triggerAction" />
                    <div>
                      <Radio.Group
                        value={
                          hasRuleAttached
                            ? ERegionOfInterestTriggerType.rule
                            : eventTrigger.trigger
                        }
                        onChange={onToggleRoiTrigger}
                        aria-label="RoI Trigger Toggle"
                        id="draw.form.roi.triggerAction"
                        disabled={
                          eventTrigger &&
                          eventTrigger.roiType === ERegionOfInterestType.parking
                        }
                      >
                        <Radio.Button
                          value={ERegionOfInterestTriggerType.time}
                          disabled={hasRuleAttached}
                        >
                          {t('draw.form.roi.triggerAction.values.time')}
                        </Radio.Button>
                        <Radio.Button
                          value={ERegionOfInterestTriggerType.change}
                          disabled={hasRuleAttached}
                        >
                          {t('draw.form.roi.triggerAction.values.change')}
                        </Radio.Button>
                        <Radio.Button
                          value={ERegionOfInterestTriggerType.rule}
                          disabled={!hasRuleAttached}
                        >
                          {t('draw.form.roi.triggerAction.values.rule')}
                        </Radio.Button>
                      </Radio.Group>
                    </div>
                  </div>
                </>
              )}

              <div>
                <FormLabelWithTooltip id="draw.form.roi.capacity" />
                <NumberInput
                  id={'draw.form.roi.capacity'}
                  invalidText=""
                  className="bx--number-input"
                  allowEmpty={false}
                  min={1}
                  max={100}
                  placeholder={t('draw.form.roi.capacity.placeholder')}
                  value={eventTrigger && eventTrigger.capacity}
                  onChange={onChangeRoiCapacity}
                />
              </div>
            </>
          )}

        {eventTrigger &&
          eventTrigger.objectType === EEventTriggerType.crossingLine && (
            <>
              <div>
                <FormLabelWithTooltip id="draw.form.crossingLine.name" />
                <TextInput
                  id="draw.form.cl.name"
                  className="scc--formfield__value-set"
                  disabled={false}
                  invalid={false}
                  invalidText={t('draw.form.invalidText')}
                  labelText={false}
                  placeholder={t('draw.form.crossingLine.name.placeholder')}
                  name="eventTrigger.name"
                  defaultValue={eventTrigger ? eventTrigger.name : ''}
                  ref={register}
                  onChange={onValueChanged}
                />
              </div>
              <div>
                <p className="scc--tooltip--label">
                  {t('draw.form.crossingLine.direction.labelText')}
                </p>
                {directionToggle(eventTrigger, onToggleDirection, t)}
              </div>
              <div>
                <FormLabelWithTooltip id="draw.form.crossingLine.customINOUTDirectionName" />
                <TextInput
                  id={'draw.form.crossingLine.customINOUTDirectionName.inLabel'}
                  className="scc--formfield__value-set"
                  disabled={false}
                  invalid={false}
                  invalidText={t('draw.form.invalidText')}
                  labelText={t(
                    'draw.form.crossingLine.customINOUTDirectionName.inLabel.labelText'
                  )}
                  placeholder={t(
                    'draw.form.crossingLine.customINOUTDirectionName.inLabel.placeholder'
                  )}
                  name="eventTrigger.inLabel"
                  defaultValue={
                    eventTrigger.inLabel ? eventTrigger.inLabel : ''
                  }
                  ref={register}
                  onChange={onValueChanged}
                />
                <TextInput
                  id={
                    'draw.form.crossingLine.customINOUTDirectionName.outLabel'
                  }
                  className="scc--formfield__value-set"
                  disabled={false}
                  invalid={false}
                  invalidText={t('draw.form.invalidText')}
                  labelText={t(
                    'draw.form.crossingLine.customINOUTDirectionName.outLabel.labelText'
                  )}
                  placeholder={t(
                    'draw.form.crossingLine.customINOUTDirectionName.outLabel.placeholder'
                  )}
                  name="eventTrigger.outLabel"
                  defaultValue={
                    eventTrigger.outLabel ? eventTrigger.outLabel : ''
                  }
                  ref={register}
                  onChange={onValueChanged}
                />
              </div>
              <div>
                <FormLabelWithTooltip id="draw.form.crossingLine.multi" />
                <div>
                  <Switch
                    checkedChildren={t('draw.toggle.on')}
                    unCheckedChildren={t('draw.toggle.off')}
                    checked={eventTrigger.multipleCrossings}
                    onChange={onToggleMultipleCrossings}
                  />
                </div>
              </div>
              <div>
                <FormLabelWithTooltip id="draw.form.crossingLine.path" />
                <div>
                  <Switch
                    checkedChildren={t('draw.toggle.on')}
                    unCheckedChildren={t('draw.toggle.off')}
                    checked={eventTrigger.attachMovementPath}
                    onChange={onToggleAttachMovementPath}
                  />
                </div>
              </div>
              <div>
                <FormLabelWithTooltip id="draw.form.crossingLine.speed" />
                <div>
                  <Switch
                    checkedChildren={t('draw.toggle.on')}
                    unCheckedChildren={t('draw.toggle.off')}
                    checked={
                      eventTrigger.speedEstimation &&
                      eventTrigger.speedEstimation.enabled
                    }
                    onChange={onToggleSpeedEstimate}
                  />
                </div>
                {eventTrigger.speedEstimation &&
                  eventTrigger.speedEstimation.enabled && (
                    <div>
                      <FormLabelWithTooltip id="draw.form.crossingLine.speed.distance" />
                      <TextInput
                        id={'draw.form.crossingLine.speed.distance'}
                        invalidText={t(
                          'draw.form.crossingLine.speed.distance.invalidText'
                        )}
                        className="bx--number-input"
                        name="eventTrigger.speedEstimation.distance"
                        defaultValue={
                          eventTrigger.speedEstimation
                            ? eventTrigger.speedEstimation.distance
                            : 1
                        }
                        invalid={
                          errors['eventTrigger'] &&
                          errors['eventTrigger']['speedEstimation'] &&
                          errors['eventTrigger']['speedEstimation']['distance']
                        }
                        onChange={onValueChanged}
                        ref={register({
                          required: true,
                          pattern: /^[+]?(\d+)?[0-9](\d+)?(\.\d+)?$/
                        })}
                      />
                    </div>
                  )}
              </div>
            </>
          )}

        {eventTrigger &&
          eventTrigger.objectType === EEventTriggerType.virtualDoor && (
            <div>
              <FormLabelWithTooltip id="draw.form.door.name" />
              <TextInput
                id="draw.form.door.name"
                className="scc--formfield__value-set"
                disabled={false}
                invalid={false}
                labelText={false}
                placeholder={t('draw.form.door.name.placeholder')}
                name="eventTrigger.name"
                defaultValue={eventTrigger ? eventTrigger.name : ''}
                onChange={onValueChanged}
                ref={register}
              />
            </div>
          )}

        {eventTrigger &&
          eventTrigger.objectType ===
            EEventTriggerType.originDestinationZone && (
            <div>
              <FormLabelWithTooltip id="draw.form.zone.name" />
              <TextInput
                id="draw.form.zone.name"
                className="scc--formfield__value-set"
                disabled={false}
                invalid={false}
                labelText={false}
                placeholder={t('draw.form.zone.name.placeholder')}
                name="eventTrigger.name"
                defaultValue={eventTrigger ? eventTrigger.name : ''}
                onChange={onValueChanged}
                ref={register}
              />
            </div>
          )}
      </Space>
    </div>
  )
}

export const GlobalTriggerSettings = ({
  onValueChanged,
  heatMap,
  onToggleHeatmap,
  shouldShowLPMode,
  anpr,
  anprOptionaAvailable,
  anonymisedAnprOptionAvailable,
  enableRoiInterval,
  roiInterval,
  onLPModeChange,
  lpMode,
  anprStorageDurationChanged,
  register,
  onButtonClick,
  enableFocusArea,
  onToggleAttachLicensePlateImage,
  hasCustomMqttConfig,
  attachLicensePlateImage
}) => {
  const { t } = useTranslation()
  let enableMaxFocusAreaTooltip = !enableFocusArea
    ? {}
    : {
        visible: false
      }
  return (
    <div>
      <Space direction={'vertical'} size="large" style={{ width: '100%' }}>
        <div>
          <FormLabelWithTooltip id="draw.form.heatMap.tooltip" />
          <div>
            <Switch
              checkedChildren={t('draw.toggle.on')}
              unCheckedChildren={t('draw.toggle.off')}
              checked={heatMap && heatMap.enabled}
              onChange={onToggleHeatmap}
            />
          </div>
        </div>

        <LPModeSelection
          shouldShowLPMode={shouldShowLPMode}
          anprOptionaAvailable={anprOptionaAvailable}
          anonymisedAnprOptionAvailable={anonymisedAnprOptionAvailable}
          anpr={anpr}
          anprStorageDurationChanged={anprStorageDurationChanged}
          register={register}
          lpMode={lpMode}
          onLPModeChange={onLPModeChange}
        />

        {hasCustomMqttConfig && lpMode === EEventTriggerType.anpr && (
          <div>
            <FormLabelWithTooltip id="draw.form.anpr.attachLicensePlateImage.tooltip" />
            <div>
              <Switch
                checkedChildren={t('draw.toggle.on')}
                unCheckedChildren={t('draw.toggle.off')}
                checked={attachLicensePlateImage}
                onChange={onToggleAttachLicensePlateImage}
              />
            </div>
          </div>
        )}

        <div>
          <FormLabelWithTooltip id={'draw.form.roi.interval'} />
          <NumberInput
            id={'draw.form.roi.interval'}
            invalidText=""
            className="bx--number-input"
            allowEmpty={false}
            min={0}
            max={3600}
            placeholder={t('draw.form.roi.interval.placeholder')}
            value={roiInterval}
            onChange={onValueChanged}
            disabled={!enableRoiInterval}
            name="stream.roiInterval"
            ref={register({ required: false })}
          />
        </div>
        <div className="draw-config-form__buttons">
          <Tooltip
            placement="bottomLeft"
            title={t('configuration.maxFocusAreaWarning')}
            {...enableMaxFocusAreaTooltip}
          >
            <Button
              className="ant-btn"
              disabled={!enableFocusArea}
              onClick={(event) => {
                onButtonClick(
                  event,
                  EEventTriggerType.regionOfInterest,
                  ERegionOfInterestType.focus
                )
              }}
            >
              {t('draw.form.buttonAddFocusArea')}
            </Button>
          </Tooltip>
        </div>
      </Space>
    </div>
  )
}

const LPModeSelection = ({
  shouldShowLPMode,
  anonymisedAnprOptionAvailable,
  anpr,
  lpMode,
  onLPModeChange,
  anprOptionaAvailable,
  anprStorageDurationChanged,
  register
}) => {
  const { t } = useTranslation()
  const lpModeValue = lpMode ? lpMode : EEventTriggerType.disabled
  return (
    <>
      {shouldShowLPMode && (
        <div>
          <FormLabelWithTooltip id="draw.form.lpMode.tooltip" />
          <div>
            <Select
              dropdownMatchSelectWidth={false}
              aria-label="LP Mode Toggle"
              id="draw.form.lp.mode"
              value={lpModeValue}
              onSelect={onLPModeChange}
            >
              <Select.Option value={EEventTriggerType.disabled}>
                {t('draw.form.lpMode.disabled')}
              </Select.Option>
              <Select.Option
                value={EEventTriggerType.anpr}
                disabled={
                  !userIsAtLeast(UserRole.Admin) || !anprOptionaAvailable
                }
              >
                {t('draw.form.lpMode.anpr')}
              </Select.Option>
              <Select.Option
                value={EEventTriggerType.gdpr}
                disabled={!anonymisedAnprOptionAvailable}
              >
                {t('draw.form.lpMode.gdpr')}
              </Select.Option>
              <Select.Option
                value={EEventTriggerType.journeyTime}
                disabled={!anonymisedAnprOptionAvailable}
              >
                {t('draw.form.lpMode.journeyTime')}
              </Select.Option>
            </Select>
          </div>
        </div>
      )}
      {anpr && anpr.enabled && (
        <div>
          <FormLabelWithTooltip id="draw.form.anpr.anprStorageTime.tooltip" />
          <NumberInput
            id={'draw.form.anpr.anprStorageTime'}
            invalidText=""
            className="bx--number-input"
            allowEmpty={true}
            min={1}
            placeholder={t('draw.form.anpr.anprStorageTime.placeholder')}
            name="anpr.anprStorageTime"
            value={anpr?.anprStorageTime ? anpr.anprStorageTime : ''}
            onChange={anprStorageDurationChanged}
            ref={register({ required: false })}
          />
        </div>
      )}
    </>
  )
}

const directionToggle = (eventTrigger, onClick, t) => {
  const direction = (eventTrigger as CrossingLineEventTrigger).direction

  const leftGlyphClass =
    direction === 'in'
      ? 'scc--crossingline--direction--caret on'
      : 'scc--crossingline--direction--caret'

  const rightGlyphClass =
    direction === 'out'
      ? 'scc--crossingline--direction--caret on'
      : 'scc--crossingline--direction--caret'

  return (
    <div
      className="scc--crossingline--direction--wrapper"
      onClick={(event) => {
        onClick(event, eventTrigger)
      }}
    >
      <div className="scc--crossingline--direction">
        <CaretLeftGlyph className={leftGlyphClass} />
        <CaretRightGlyph className={rightGlyphClass} />
      </div>
      {direction === 'in' && (
        <label className="bx--label">
          {t('draw.form.crossingLine.direction.in')}&nbsp;|&nbsp;
          {t('draw.form.crossingLine.direction.out')}
        </label>
      )}
      {direction === 'out' && (
        <label className="bx--label">
          {t('draw.form.crossingLine.direction.out')}&nbsp;|&nbsp;
          {t('draw.form.crossingLine.direction.in')}
        </label>
      )}
    </div>
  )
}
